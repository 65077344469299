<template>
  <!-- 相册抽屉-常规默认展示 -->
  <section class="m-album animate__animated animate__fadeInLeft">
    <header class="m-album-hd" v-if="showHeader">
      <div class="album-back" @click="goBack">
        <img src="../assets/baseAlbum/goback-arrow.svg" />
      </div>
      <div class="album-type">
        <img class="album-type-icon" :src="options.icon" :alt="options.icon">
        <h3 class="album-type-name">{{options.name}}</h3>
      </div>
      <div class="album-close"></div>
    </header>
    <main class="m-album-bd">
      <h5 class="album-desc truncate">{{options.desc}}</h5>
      <div class="album-body">
        <slot name="article" v-if="showArticle">
          <!--
                  <article class="pic">
                    <figure class="pic-item">

                        <figcaption class="pic-item-hd">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#gshb_move"></use>
                            </svg>
                            <h6 class="name">blend_coarse</h6>
                            <div class="close"></div>
                        </figcaption>

                        <img class="subject" src="../assets/senseGeo/album/geo-1.png" alt="">
                    </figure>
                    <figure class="pic-item">

                        <figcaption class="pic-item-hd">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#gshb_move"></use>
                            </svg>
                            <h6 class="name">blend_coarse</h6>
                            <div class="close"></div>
                        </figcaption>

                        <img class="subject" src="../assets/senseGeo/album/geo-1.png" alt="">
                    </figure>
                    <figure class="pic-item">
                        <figcaption class="pic-item-hd">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#gshb_move"></use>
                            </svg>
                            <h6 class="name">blend_coarse</h6>
                            <div class="close"></div>
                        </figcaption>
                        <img class="subject" src="../assets/senseGeo/album/geo-1.png" alt="">
                    </figure>
                  </article>
                  -->
        </slot>
        <slot name="aside" v-if="showAside">
          <!--
                    <aside class="aside">
                        <ul class="pic-name-list">
                            <li class="name-item name-item-active">
                                <svg class="icon move" aria-hidden="true">
                                    <use xlink:href="#gshb_img"></use>
                                </svg>
                                <h6 class="name">blend_coarse1.png</h6>
                            </li>
                            <li class="name-item">
                                <svg class="icon move" aria-hidden="true">
                                    <use xlink:href="#gshb_img"></use>
                                </svg>
                                <h6 class="name">blend_coarse2.png</h6>
                            </li>
                            <li class="name-item">
                                <svg class="icon move" aria-hidden="true">
                                    <use xlink:href="#gshb_img"></use>
                                </svg>
                                <h6 class="name">blend_coarse3.png</h6>
                            </li>
                        </ul>
                    </aside>
                    -->
        </slot>
      </div>
    </main>
    <slot name="footer" v-if="showFooter">
      <!--
            <footer class="m-album-ft">
                <svg class="values" aria-hidden="true">
                    <use xlink:href="#gshb_prev1"></use>
                </svg>
                <div class="album-thumb">
                    <figure class="thumb-item">
                        <div class="subject">
                            <img class="linear-box" src="../assets/indusVision/album/geo-1.png" alt="">
                        </div>
                        <figcaption class="name">blend_coarse1.png</figcaption>
                    </figure>
                    <figure class="thumb-item thumb-item-active">
                        <div class="subject">
                            <img class="linear-box" src="../assets/indusVision/album/geo-1.png" alt="">
                        </div>
                        <figcaption class="name">blend_coarse1.png</figcaption>
                    </figure>
                    <figure class="thumb-item">
                        <div class="subject">
                            <img class="linear-box" src="../assets/indusVision/album/geo-1.png" alt="">
                        </div>
                        <figcaption class="name">blend_coarse1.png</figcaption>
                    </figure>
                    <figure class="thumb-item">
                        <div class="subject">
                            <img class="linear-box" src="../assets/indusVision/album/geo-thumb-1.png" alt="">
                        </div>
                        <figcaption class="name">blend_coarse1.png</figcaption>
                    </figure>

                </div>
                <svg class="values" aria-hidden="true">
                    <use xlink:href="#gshb_next"></use>
                </svg>
            </footer>
            -->
    </slot>
  </section>
  <!-- 相册抽屉-常规默认展示end -->
</template>

<script>
export default {
    name: "BaseAlbum",
    props: {
      options: {
        type: Object,
        require: true,
        default: function() {
          return {}
        }
      },
      showHeader: {
        type: Boolean,
        require: false,
        default: true
      },
      showArticle: {
        type: Boolean,
        require: false,
        default: true
      },
      showAside: {
        type: Boolean,
        require: false,
        default: true
      },
      showFooter: {
        type: Boolean,
        require: false,
        default: true
      }
    },
    methods: {
      goBack: function() {
        this.$router.back()
      }
    }
}
</script>

<style lang="less">
@charset "utf-8";

/**
 * @description 图片展示全屏抽屉
 * @author xing.wu
 * @ctreate 2021/8/12
 */

@import "../lib/base.less";

.m-album {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: #393A3F;
  &-hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 11.5vh;
    .album {
      &-back {
        font-size: 52.5px;
        line-height: 37.5px;
        margin-left: 10px;
        cursor: pointer;
      }
      &-type {
        display: flex;
        justify-content: center;
        align-items: center;
        &-icon {
          min-width: 50px;
          max-width: 70px;
          max-height: 58px
        }
        &-name {
          margin: 0 15px;
          font-size: 20px;
          //line-height: 34px;
        }
      }
    }
  }
  &-bd {
    height: calc(100% - 61px);
    background: #242527;
    .album {
      &-desc {
        height: 44px;
        font-size: 16px;
        line-height: 44px;
        text-align: center;
        border-top: 1px solid #0F1011;
        border-bottom: 1px solid #0F1011;
        background: #242527;
      }
      &-body {
        display: flex;
        justify-content: center;
        // 减去body区域标题的高度
        height: calc(100% - 46px);
        background: #393A3F;
        .pic {
          display: flex;
          width: 100%;
          &-item {
            width: 100%;
            border-right: 1px solid #0F1011;
            cursor: pointer;
            &:last-child {
              border-right: 0;
            }
            &-hd {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 44px;
              border-bottom: 1px solid #0F1011;
              background: #242527;
              .move {
                font-size: 16px;
                margin-left: 10px;
              }
              .name {
                @apply text-xs lg:text-lg pl-1;
              }
            }
            .subject {
              box-sizing: border-box;
              overflow: scroll;
              width: 100%;
              height: 100%;
              object-fit: cover;
              padding: 10px;
            }
          }
        }
      }
    }
    .aside {
      width: 200px;
      max-width: 23.5vw;
      border-left: 1px solid #0F1011;
      .name-item {
        display: flex;
        align-items: center;
        height: 44px;
        font-size: 14px;
        border-bottom: 1px solid #0F1011;
        cursor: pointer;
        &:hover,
        &-active {
          background-color: #54555A;
        }
        .move {
          font-size: 14px;
          margin: 0 5px;
        }
        .name {
          font-size: 14px;
        }
      }
    }
  }
  &-ft {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 16vh;
    background: #393A3F;
    .thumb-swiper-btn {
      box-sizing: border-box;
      width: 50px;
      height: 50px;
      line-height: 50px;
      margin: 0 15px;
      border: 1px solid #949494;
      border-radius: 25px;
      font-size: 18px;
      text-align: center;
      background: rgba(255, 255, 255, 0.2);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
      cursor: pointer;
      user-select: none;
      &-disable {
        cursor: default;
        color: #909297;
      }
    }
    .album-thumb {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(60vh + 80px);
      height: 100%;
      .thumb-item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 15vh !important;
        height: 100%;
        margin-left: 10px;
        margin-right: 10px;
        overflow: hidden;
        color: #909297;
        .subject {
           width: 15vh !important;
           height: 10vh !important;
          .linear-box {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            border: 1px solid @gBgColor;
            border-radius: 8px;
          }
        }

        .name {
          font-size: 14px;
          line-height: 23px;
          text-align: center;
        }

        &:hover,
        &-active{
          color: @gColor;
          .subject {
            .g-linear-gradient-border(@width: auto; @height: 60%;);
          }
        }
      }
    }
  }
  // 多列可拖拽,图片带标题,标题栏自占高度情况
  &-name-move {
    opacity: .5;
    .album-body {
      .pic-item {
        .subject {
          // 减去图片标题的高度
          height: calc(100% - 45px);
        }
      }
      
    }
  }
  // 轮播图,样式兼容
  &-carousel {
    .m-album-bd {
        height: 72.5vh;
    }
    .album-body {
      .pic-item {
        width: 100%;
        height: 100%;
        .subject {
          width: 100%;
          height: 100%;
          padding: 0 !important;
        }
      }
    }
  }
}
</style>