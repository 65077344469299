<template>
    <!-- 遥感和地理信息页start -->
    <section class="p-sense-geo">
        <SenseGeoTech />
        <SenseGeoSoft />
        <SenseGeoApp />
        <!-- 相册抽屉start -->
        <router-view/>
        <!-- 相册抽屉end -->
    </section>
    <!-- 遥感和地理信息页end -->
</template>

<script>
// @ is an alias to /src
import SenseGeoTech from '@/components/SenseGeoTech.vue'
import SenseGeoSoft from '@/components/SenseGeoSoft.vue'
import SenseGeoApp from '@/components/SenseGeoApp.vue'

export default {
    name: 'SenseGeo',
    components: {
        SenseGeoTech,
        SenseGeoSoft,
        SenseGeoApp,
    }
}
</script>

<style lang="less">
    .p-sense-geo {
        position: relative;
        width: 100%;
        padding-bottom: 165px;
    }
</style>