<template>
    <BaseAlbumSwiper 
        :album-data="albumData"
    />
</template>

<script>
import BaseAlbumSwiper from '@/components/BaseAlbumSwiper.vue'

export default {
    name: "SenseGeoBuildingExtraction",
    components: {
        BaseAlbumSwiper
    },
    computed: {
        albumData: function() {
            return {
                icon: require("../assets/senseGeo/app/house-collect.svg"),
                name: this.$t('senseGeo.app.buildingExtraction.name'),
                desc: this.$t('senseGeo.app.buildingExtraction.desc'),
                list: [{
                    key: "Subset1_blended",
                    name: "Subset1_blended",
                    src: require("../assets/senseGeo/album/Subset1_blended.jpg")
                },{
                    key: "Subset2_blended",
                    name: "Subset2_blended",
                    src: require("../assets/senseGeo/album/Subset2_blended.jpg")
                },{
                    key: "Subset3_blended",
                    name: "Subset3_blended",
                    src: require("../assets/senseGeo/album/Subset3_blended.jpg")
                },{
                    key: "Subset4_blended",
                    name: "Subset4_blended",
                    src: require("../assets/senseGeo/album/Subset4_blended.jpg")
                }],
                thumb: [{
                    key: "Subset1_blended",
                    name: "Subset1_blended",
                    src: require("../assets/senseGeo/album/Subset1_blended.jpg")
                },{
                    key: "Subset2_blended",
                    name: "Subset2_blended",
                    src: require("../assets/senseGeo/album/Subset2_blended.jpg")
                },{
                    key: "Subset3_blended",
                    name: "Subset3_blended",
                    src: require("../assets/senseGeo/album/Subset3_blended.jpg")
                },{
                    key: "Subset4_blended",
                    name: "Subset4_blended",
                    src: require("../assets/senseGeo/album/Subset4_blended.jpg")
                }]
            }
        }
    }
}
</script>

<i18n>
{
    "zh": {
        
    },
    "en": {
        
    }
}
</i18n>

<style lang="less" scoped>

</style>