<template>
  <div class="m-ai-circle md:-top-32">
    <img class="rotate-bg" src="../assets/aiControl/m-ai-circle-bg.png" alt="rotate-bg" />
    <img class="gh-core-img" src="../assets/aiControl/m-ai-core.svg" alt="SX Core" />
  </div>
</template>

<script>
export default {
  name: "AiControlCircle",
};
</script>

<style lang="less" scoped>
@import "../lib/base.less";

.m-ai-circle {
  position: absolute;
  z-index: -1;
  left: 50%;
  transform: translate(-50%);
  margin: 0 auto;
  overflow: hidden;

  .rotate-bg {
    width: 950px;
    height: 950px;
    animation: gshb-target-rotate 5s linear infinite;
  }
  .gh-core-img {
    position: absolute;
    left: 50%;
    top: 53%;
    transform: translate(-50%, -50%);
  }
}
</style>
