<template>
  <section class="m-indus-sys relative mt-36">
    <div :id="tsparticlesDomId" class="particles-container"></div>
    <BaseIntro :showName="true" :showSubTitle="true">
      <template v-slot:type>
        {{ $t("technology") }}
      </template>
      <template v-slot:name>
        <p class="">{{ $t("intro.name") }}</p>
      </template>
      <template v-slot:subtitle>
        <p class="text-center">{{ $t("intro.subTitle") }}</p>
      </template>
    </BaseIntro>
    <IndusVisionFlow class="m-indus-sys-bd" />
    <div class="guide-line"></div>
  </section>
</template>

<script>
// @ is an alias to /src
import { tsParticles } from "tsparticles";
import IndusVisionFlow from "@/components/IndusVisionFlow.vue";
import BaseIntro from "@/components/BaseIntro.vue";
import tsparticlesjson from "@/assets/indusVision/particles.json";

export default {
  name: "IndusVisionSys",
  components: {
    IndusVisionFlow,
    BaseIntro,
  },
  data() {
    return {
      tsparticlesDomId:
        "particles-instance-" + Math.floor(Math.random() * 5000),
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initParticleJS();
    });
  },
  methods: {
    initParticleJS: function() {
      tsParticles.load(this.tsparticlesDomId, tsparticlesjson);
    },
  },
};
</script>

<i18n>
{
    "zh": {
        "intro": {
            "name": "SXAI工业视觉检测系统",
            "subTitle": "SX核心"
        }
    },
    "en": {
        "intro": {
            "name": "SXAI Industry Vision Identification Platform",
            "subTitle": "SX Core"
        }
    }
}
</i18n>

<style lang="less" scoped>
.m-indus-sys {
  position: relative;
  //height: 677px;
  margin-top: 134px;
  ::v-deep .m-intro-name {
    margin: 30px 0 65px 0;
  }
  .particles-container {
    position: absolute;
    z-index: -1;
    top: -74px;
    width: 100%;
    height: 950px;
  }
  .guide-line {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translate(-50%, 0);
    width: 2px;
    height: 70px;
    background: #30374a;
  }
  &-bd {
    //top: -120px;
  }
}
</style>
