<template>
    <BaseAlbumDrag 
        :album-data="albumData"
    />
</template>

<script>
import BaseAlbumDrag from '@/components/BaseAlbumDrag.vue'

export default {
    name: "SenseGeoRsObjectDetection",
    components: {
        BaseAlbumDrag
    },
    computed: {
        albumData: function() {
            return {
                icon: require("../assets/senseGeo/app/sense-target-detect.svg"),
                name: this.$t('senseGeo.app.rsObjectDetection.name'),
                desc: this.$t('senseGeo.app.rsObjectDetection.desc'),
                list: [{
                    key: "album_3",
                    name: "blend",
                    src: require("../assets/senseGeo/album/sense-target-detect-1.png")
                },{
                    key: "album_1",
                    name: "blend_coarse",
                    src: require("../assets/senseGeo/album/sense-target-detect-2.png")
                }]
            }
        }
    }
}
</script>

<i18n>
{

}
</i18n>

<style lang="less" scoped>

</style>