<template>
  <h1 class="text-center font-semibold text-6xl tracking-wider leading-10 animate__animated animate__bounceInRight">
    {{ $t("company.shortName") }}
  </h1>
</template>

<script>
export default {
  name: "ProfileInfo",
};
</script>

<style lang="less" scoped>
</style>
