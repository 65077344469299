<template>
  <ol class="m-ai-flow transform scale-50 md:scale-100 rotate-90	md:rotate-0">
    <li class="m-ai-flow-item">
      <div class="linear-btn-large flow-point-core">
        <div class="linear-box">{{ $t("ghaiPlatform") }}</div>
      </div>
    </li>
    <li class="m-ai-flow-item animate__animated animate__fadeInRight">
      <img src="../assets/aiControl/flow/arrow-one-way.png" alt="arrow-one-way" />
    </li>
    <li class="m-ai-flow-item animate__animated animate__fadeInRight">
      <div class="linear-btn-large">
        <div class="linear-box">{{ $t("armBasedCpuCore") }}</div>
      </div>
    </li>
    <li class="m-ai-flow-item flow-arrow-both-way animate__animated animate__fadeInRight animate__slow">
      <img :src="flowArrowBothWay" alt="arrow-both-way" />
      <img src="../assets/aiControl/flow/arrow-multi-level.svg" alt="arrow-multi-level" />
    </li>
    <li class="m-ai-flow-item flow-point-multi animate__animated animate__fadeInRight animate__slow">
      <div class="linear-btn flow-point-multi-item">
        <div class="linear-box">{{ $t("cloudDevicesVia5G") }}</div>
      </div>
      <div class="linear-btn flow-point-multi-item">
        <div class="linear-box">{{ $t("slamForEnvPerception") }}</div>
      </div>
      <div class="linear-btn flow-point-multi-item">
        <div class="linear-box">{{ $t("algorithmFramework") }}</div>
      </div>
    </li>
    <li class="m-ai-flow-item animate__animated animate__fadeInRight animate__slower">
      <img src="../assets/aiControl/flow/arrow-collect.png" alt="arrow-collect" />
    </li>
    <li class="m-ai-flow-item animate__animated animate__fadeInRight animate__slower">
      <div class="linear-btn-large flow-point-confluence">
        <div class="linear-box">
          {{ $t("oneBaseCodeMultipleDevelopments") }}
        </div>
      </div>
    </li>
  </ol>
</template>

<script>
export default {
  name: "AiControlFlow",
  data: function() {
    return {
      arrowBothWay: require("../assets/aiControl/flow/arrow-both-way.png"),
      arrowBothWayLong: require("../assets/aiControl/flow/arrow-both-way-long.png"),
    };
  },
  computed: {
    flowArrowBothWay: function() {
      let isLangEn = this.$root.$i18n.locale === "en";
      return isLangEn ? this.arrowBothWayLong : this.arrowBothWay;
    },
  },
};
</script>

<i18n>
{
    "zh": {
        "ghaiPlatform": "SXAI图像序列目标标注及检测模型训练系统",
        "armBasedCpuCore": "ARM架构和通用CPU核心",
        "cloudDevicesVia5G": "结合5G技术的云边端互动",
        "slamForEnvPerception": "基于双目视觉的SLAM环境感知",
        "algorithmFramework": "核心目标检测及跟踪算法框架",
        "oneBaseCodeMultipleDevelopments": "零代码二次开发支持多种嵌入设备行业应用深度贴合"
    },
    "en":  {
        "ghaiPlatform": "SXAI platform for labeling objects and training/identification",
        "armBasedCpuCore": "ARM-based and general CPU core",
        "cloudDevicesVia5G": "Interaction with cloud-based devices via 5G",
        "slamForEnvPerception": "Stereo vision-based SLAM for environment perception",
        "algorithmFramework": "A framework of detecting and tracking key objects",
        "oneBaseCodeMultipleDevelopments": "One base code, multiple developments Support multi types of embedded devices Fit industry applications closely"
    }
}
</i18n>

<style lang="less" scoped>
@import "../lib/common.less";

.m-ai-flow {
  display: flex;
  justify-content: center;
  align-items: center;
  &-item {
    margin: 0 4px;
    padding: 0 1px;
    .linear-btn-large {
      .g-linear-btn-large();
      .lang-en & {
        .linear-box {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
    .linear-btn {
      .g-linear-btn-padding();
    }
    .flow-point {
      &-multi-item {
        margin: 15px 0;
        .lang-en & {
          max-width: 290px;
          .linear-box {
            padding: 5px 30px;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
      &-core {
        .lang-en & {
          max-width: 380px;
        }
      }
      &-confluence {
        max-width: 234px;
        height: inherit;
        .lang-en & {
          max-width: 394px;
        }
      }
    }
  }
  .flow-arrow-both-way {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
