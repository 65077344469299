<template>
  <div
    class="m-com-values grid grid-cols-2 lg:grid-cols-4 gap-x-2 md:gap-x-6 justify-evenly m-2 md:m-8 animate__animated animate__zoomInLeft animate__slow">
    <div class="value box-border ">
      <div class="value-icon">
        <img src="../assets/profile/values/value.svg" :alt="$t('company.value.name')">
      </div>
      <h2 class="value-type-faith">{{ $t('company.value.name') }}</h2>
      <p class="value-slogan">{{ $t('company.value.desc') }}</p>
    </div>
    <div class="value box-border animate__animated animate__zoomIn">
      <div class="value-icon">
        <img src="../assets/profile/values/mission.svg" :alt="$t('company.mission.name')">
      </div>
      <h2 class="value-type-mission">{{ $t('company.mission.name') }}</h2>
      <p class="value-slogan">{{ $t('company.mission.desc') }}</p>
    </div>
    <div class="value box-border animate__animated animate__zoomIn">
      <div class="value-icon">
        <img src="../assets/profile/values/vision.svg" :alt="$t('company.vision.name')">
      </div>
      <h2 class="value-type-wish">{{ $t('company.vision.name') }}</h2>
      <p class="value-slogan">{{ $t('company.vision.desc') }}</p>
    </div>
    <div class="value box-border animate__animated animate__zoomInRight animate__slow animate__repeat-1">
      <div class="value-icon">
        <img src="../assets/profile/values/objective.svg" :alt="$t('company.objective.name')">
      </div>
      <h2 class="value-type-target">{{ $t('company.objective.name') }}</h2>
      <p class="value-slogan">{{ $t('company.objective.desc') }}</p>
    </div>
  </div>
</template>

<script>
export default {
    name: "ProfileValues"
}
</script>

<style lang="less" scoped>
@import "../lib/base.less";

@value-color: #1C2331;

.m-com-values {
  .value {
    &:hover {
      .value-icon {
        animation: bounce; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 1s; /* don't forget to set a duration! */
      }
      .value-slogan {
        animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 2s; /* don't forget to set a duration! */
      }
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 114px;
    }
    &-type {
      &-faith {
        .g-linear-gradient-btn-bg();
      }
      &-mission {
        .g-linear-gradient-btn-bg(@linear: #FFC000,  #F58315; @color: @value-color);
      }
      &-wish {
        .g-linear-gradient-btn-bg(@linear: #6ED9F1,  #21A5DD; @color: @value-color);
      }
      &-target {
        .g-linear-gradient-btn-bg(@linear: #9169D9,  #6B3DD8;);
      }
    }
    &-slogan {
      margin: 20px ;
      padding: 1px;
      text-align: left;
      font-size: 18px;
      line-height: 25px;
    }
  }
}
</style>