<template>
    <section
        class="flex flex-col justify-around h-60 bg-green-900 bg-opacity-20 animate__animated animate__bounceInLeft">
        <h2 class="text-center text-4xl">{{ $t('title') }}</h2>
        <ul class="grid grid-cols-3 lg:grid-cols-6 gap-x-2 md:gap-x-6 justify-evenly">
            <li class="customer rounded-lg" v-for="(customer) in customerList" :key="customer.name">
                <a class="flex justify-center align-middle" :href="customer.href" target="_blank" rel="external">
                    <img :src="customer.icon" :alt="customer.name">
                </a>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    name: "ProfileCustomer",
    data: function() {
        return {
            customerList: [{
                name: "vw",
                href: "https://www.vw.com.cn/",
                icon: require("../assets/profile/customer/vw-icon.png"),
            },{
                name: "yanfeng",
                href: "https://www.yanfeng.com/",
                icon: require("../assets/profile/customer/yanfeng-icon.png"),
            },{
                name: "yanfengadient",
                href: "https://www.yanfengadient.com/",
                icon: require("../assets/profile/customer/yanfengadient-icon.png"),
            },{
                name: "charmingglobe",
                href: "http://www.charmingglobe.com/",
                icon: require("../assets/profile/customer/charmingglobe-icon.png"),
            },{
                name: "gzpi",
                href: "https://www.gzpi.com.cn/",
                icon: require("../assets/profile/customer/gzpi-icon.png"),
            },{
                name: "21at",
                href: "https://www.21at.com.cn/",
                icon: require("../assets/profile/customer/21at-icon.png"),
            }]
        }
    }
}
</script>

<i18n>
{
    "zh": {
        "title": "公司主要客户"
    },
    "en": {
        "title": "Main customer of Company"
    }
}
</i18n>

<style lang="less" scoped>
    .customer {
        &:hover {
            animation: pulse; /* referring directly to the animation's @keyframe declaration */
            animation-duration: 2s; /* don't forget to set a duration! */
        }
    }
</style>