<template>
    <BaseAlbumDrag
        class="m-album-name-move"
        :album-data="albumData"
        :show-aside="true"
        :show-figcaption="true"
    />
</template>

<script>
import BaseAlbumDrag from '@/components/BaseAlbumDrag.vue'

export default {
    name: "SenseGeoLandCover",
    components: {
        BaseAlbumDrag
    },
    computed: {
        albumData: function() {
            return {
                icon: require("../assets/senseGeo/app/surface-cover.svg"),
                name: this.$t('senseGeo.app.landCover.name'),
                desc: this.$t('senseGeo.app.landCover.desc'),
                list: [{
                    key: "album_1",
                    name: this.$t('oneLevelLandCover'),
                    src: require("../assets/senseGeo/album/one-level-land-cover.png")
                },{
                    key: "album_2",
                    name: this.$t('multiLevelLandCover'),
                    src: require("../assets/senseGeo/album/multi-level-land-cover.png")
                },{
                    key: "album_3",
                    name: this.$t('originalSatelliteImage'),
                    src: require("../assets/senseGeo/album/original-satellite-image.png")
                }]
            }
        }
    }
}
</script>

<i18n>
{
    "zh": {
        "oneLevelLandCover": "单层级地表覆盖结果",
        "multiLevelLandCover": "多层级地表覆盖结果",
        "originalSatelliteImage": "原始影像"
    },
    "en": {
        "oneLevelLandCover": "One-level land cover classification",
        "multiLevelLandCover": "multi-level land cover classification",
        "originalSatelliteImage": "Original satellite image"
    }
}
</i18n>

<style lang="less" scoped>
</style>