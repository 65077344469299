<template>
    <section class="m-intro">
        <h3 class="m-intro-type">
            <slot name="type"></slot>
        </h3>
        <h2 class="m-intro-name" v-if="showName">
            <slot name="name"></slot>
        </h2>
        <section class="m-intro-bd" v-if="showIntroBody">
            <h3 class="m-intro-sub-title" v-if="showSubTitle">
                <slot name="subtitle"></slot>
            </h3>
            <div class="m-intro-content" v-if="showContent">
                <slot name="content"></slot>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    name: "BaseIntro",
    props: {
        showName: {
            type: Boolean,
            require: false,
            default: false
        },
        showSubTitle: {
            type: Boolean,
            require: false,
            default: false
        },
        showContent: {
            type: Boolean,
            require: false,
            default: false
        }
    },
    computed: {
      showIntroBody: function() {
        return this.showSubTitle || this.showContent
      }
    }
}
</script>

<style lang="less">
.m-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-type {
    padding-top: 10px;
    border-top: 3px solid #6DDCFF;
    font-weight: 600;
    font-size: 36px;
    line-height: 1.4em;
    .lang-en & {
      font-size: 30px;
    }
  }
  &-name {
    margin: 5px 0 50px 0;
    font-weight: 600;
    font-size: 48px;
    line-height: 1.4em;
    letter-spacing: 0.19em;
    text-align: center;
    .lang-en & {
      font-size: 40px;
      letter-spacing: 0;
    }
  }
  &-sub-title {
      font-size: 36px;
      line-height: 1.4em;
      color: #e0e0e0;
      .lang-en & {
        font-size: 30px;
      }
  }
  &-content {
      font-size: 24px;
      line-height: 1.4em;
      color: #B1B1B7;
      p {
        margin: .3em;
      }
      .lang-en & {
        font-size: 16px;
      }
  }
}
</style>