<template>
  <section class="m-sg-tech">
    <div class="tech-desc">
      <BaseIntro
        class="animate__animated animate__lightSpeedInLeft animate__slow"
        :showName="true"
        :showSubTitle="true"
        :showContent="true"
      >
        <template v-slot:type>
          {{ $t("technology") }}
        </template>
        <template v-slot:name>
          <span v-html="$t('intro.name')"></span>
        </template>
        <template v-slot:subtitle>
          {{ $t("intro.subTitle") }}
        </template>
        <template v-slot:content>
          <p v-for="(text, index) in $t('intro.content')" :key="index">
            {{ text }}
          </p>
        </template>
      </BaseIntro>
      <div class="td-bg"></div>
      <div
        class="td-show animate__animated animate__lightSpeedInRight animate__slow"
      >
        <img
          class="pic"
          src="../assets/senseGeo/m-tech-desc-show.png"
          alt="tech-desc-show"
        />
      </div>
    </div>
    <SenseGeoFlow />
  </section>
</template>

<script>
import BaseIntro from "@/components/BaseIntro.vue";
import SenseGeoFlow from "@/components/SenseGeoFlow.vue";

export default {
  name: "SenseGeoTech",
  components: {
    BaseIntro,
    SenseGeoFlow,
  },
};
</script>

<i18n>
{
    "zh": {
        "intro": {
            "name": "SXAI遥感数据解译系统",
            "subTitle": "主要特性",
            "content": [
                "自主研发：深度学习核心网络",
                "多模态数据处理：倾斜摄影，卫星影像，点云等",
                "易用：AI技术与传统GIS业务完美融合"
            ]
        }
    },
    "en": {
        "intro": {
            "name": "SXAI Remote Sensing Data<br/>Interpretation Platform",
            "subTitle": "Main features",
            "content": [
                "Independent R&D: Deep Learning Core Architecture",
                "Processing Multimodal Data: Oblique Photogrammetry, Satellite Images, Cloud Points et al",
                "Easy to use: Integrating AI Technology with GIS"
            ]
        }
    }
}
</i18n>

<style lang="less" scoped>
.m-sg-tech {
  margin-top: 14.5vh;

  .tech {
    &-desc {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 72vw;
      height: 602px;
      margin: 0 auto;
      .m-intro {
        align-items: flex-start;
      }
      .td-bg {
        position: absolute;
        z-index: -10;
        top: 0;
        right: 0;
        width: 75%;
        height: 100%;
        background: linear-gradient(
          224.23deg,
          #09112e 20.38%,
          rgba(9, 17, 46, 0) 86.12%
        );
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
      }
      .td-show {
        position: absolute;
        z-index: -1;
        right: 0;
      }
    }
  }
}
</style>
