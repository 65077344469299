<template>
    <BaseAlbum 
        :options="options"
        :showFooter="false"
    >
        <template v-slot:article>
            <draggable
                class="pic"
                tag="article"
                v-model="albumList"
                @choose="onChoose"
            >
                <figure 
                    class="pic-item"
                    v-for="album in albumList" :key="album.key"
                >
                    <figcaption class="pic-item-hd" v-if="showFigcaption">
                        <svg class="icon move" aria-hidden="true">
                            <use xlink:href="#gshb_move"></use>
                        </svg>
                        <h6 class="name">{{album.name}}</h6>
                        <div class="close"></div>
                    </figcaption>
                    <img class="subject" :src="album.src" alt="blend">
                </figure>
            </draggable>
        </template>
        <template v-slot:aside v-if="showAside">
            <aside class="aside hidden md:block">
                <draggable 
                    class="pic-name-list"
                    tag="ul"
                    v-model="albumList"
                    @choose="onChoose"
                >
                    <li 
                        v-for="album in albumList" :key="album.key"
                        :class="['name-item', {'name-item-active': album.active}]"
                    >
                        <span class="move">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#gshb_img"></use>
                            </svg>
                        </span>
                        <h6 class="name">{{album.name}}</h6>
                    </li>
                </draggable>
            </aside>
        </template>
    </BaseAlbum>
</template>

<script>
// @ is an alias to /src
import Draggable from 'vuedraggable'
import BaseAlbum from "@/components/BaseAlbum.vue"

export default {
    name: "BaseAlbumDrag",
    components: {
        BaseAlbum,
        Draggable
    },
    props: {
        albumData: {
            type: Object,
            require: true,
            default: function() {
                return {
                    list:[]
                }
            }
        },
        showAside: {
            type: Boolean,
            require: false,
            default: false
        },
        showFigcaption: {
            type: Boolean,
            require: false,
            default: false
        }
    },
    data: function () {
        return {
            album: this.albumData
        }
    },
    computed: {
        options: function() {
            const { icon, name, desc } = this.album;
            return {
                icon,
                name,
                desc
            }
        },
        albumList: {
            get: function() {
                return this.album.list
            },
            set: function(newList) {
                this.album.list = newList
            }
        },
    },
    methods: {
        onChoose: function(e) {
            let oldDraggableIndex = e.oldDraggableIndex
            this.album.list.forEach((album, index)=> {
                album.active = index === oldDraggableIndex
            })
        }
    }
}
</script>

<style lang="less">
</style>