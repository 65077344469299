<template>
  <section class="m-ai-core">
    <BaseIntro class="m-ai-core-hd" :showName="true" :showSubTitle="true">
      <template v-slot:type>
        {{ $t("technology") }}
      </template>
      <template v-slot:name>
        {{ $t("intro.name") }}
      </template>
      <template v-slot:subtitle>
        {{ $t("intro.subTitle") }}
      </template>
    </BaseIntro>
    <div class="m-ai-core-bd">
      <AiControlFlow />
    </div>
    <div class="m-ai-core-ft">
      <AiControlVideo />
    </div>
  </section>
</template>

<script>
import BaseIntro from "@/components/BaseIntro.vue";
import AiControlFlow from "@/components/AiControlFlow.vue";
import AiControlVideo from "@/components/AiControlVideo.vue";

export default {
  name: "AiControlCore",
  components: {
    BaseIntro,
    AiControlFlow,
    AiControlVideo,
  },
};
</script>

<i18n>
{
    "zh": {
        "intro": {
            "name": "SXAI无人机机载AI控制单元",
            "subTitle": "SX核心"
        }
    },
    "en": {
        "intro": {
            "name": "SXAI UAV Based AI Control Unit",
            "subTitle": "SX Core"
        }
    }
}
</i18n>

<style lang="less" scoped>
.m-ai-core {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  &-hd {
    ::v-deep .m-intro-name {
      margin: 20px 0 25px 0;
      .lang-en & {
        margin: 10px 0 15px 0;
      }
    }
  }
  &-bd {
    margin-top: 70px;
  }
}
</style>
