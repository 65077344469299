<template>
    <div
        class="m-sg-flow transform origin-top rotate-90 md:rotate-0 -translate-x-44 sm:-translate-x-28 lg:-translate-x-0 translate-y-56 sm:translate-y-0 scale-50 md:scale-75 lg:scale-95 xl:scale-100">
        <img class="lines-bg" src="../assets/senseGeo/flow/sf-flow-lines.svg" alt="flow-lines-bg">
        <div class="process-core">
            <figure class="cpu">
                <img class="img" src="../assets/senseGeo/flow/sf-process-unit.svg"
                    :alt="$t('MultiTaskDataProcessingUnit')">
                <figcaption class="text">{{ $t('MultiTaskDataProcessingUnit') }}</figcaption>

            </figure>
        </div>
        <div class="process-units">
            <figure class="item geo-info">
                <img class="img" src="../assets/senseGeo/flow/sf-geo-info.png" alt="geo-info">
                <figcaption class="text"></figcaption>

            </figure>
            <figure class="item">
                <img class="img" src="../assets/senseGeo/flow/sf-deep-learn-analyse.svg"
                    :alt="$t('DeepLearningCoreArchitecture')">
                <figcaption class="text">{{ $t('DeepLearningCoreArchitecture') }}</figcaption>

            </figure>
            <figure class="item">
                <img class="img" src="../assets/senseGeo/flow/sf-data-process.svg"
                    :alt="$t('ImageSegmentation') + $t('ObjectDetection') + $t('ImageClassification')">
                <figcaption class="text">
                    <span class="name name-top-left">{{ $t('ImageSegmentation') }}</span>
                    <span class="name name-top-right">{{ $t('ObjectDetection') }}</span>
                    <span class="name name-bottom">{{ $t('ImageClassification') }}</span>
                </figcaption>

            </figure>
            <figure class="item">
                <img class="img" src="../assets/senseGeo/flow/sf-result-show.svg"
                    :alt="$t('3DDisplay') + $t('ThematicMap') + $t('QuantitativeInformation')">
                <figcaption class="text">
                    <span class="name name-top">{{ $t('3DDisplay') }}</span>
                    <span class="name name-bottom-left">{{ $t('ThematicMap') }}</span>
                    <span class="name name-bottom-right">{{ $t('QuantitativeInformation') }}</span>
                </figcaption>

            </figure>
        </div>
    </div>
</template>

<script>
export default {
    name: "SenseGeoFlow"
}
</script>

<i18n>
{
    "zh": {
        "MultiTaskDataProcessingUnit": "不同任务信息处理单元",
        "DeepLearningCoreArchitecture": "深度学习核心网络框架",
        "ImageSegmentation": "像素级解译",
        "ObjectDetection": "目标检测",
        "ImageClassification": "高级对象解译",
        "3DDisplay": "三维展示",
        "ThematicMap": "专题地图",
        "QuantitativeInformation": "定量信息"
    },
    "en": {
        "MultiTaskDataProcessingUnit": "Multi-task data processing unit",
        "DeepLearningCoreArchitecture": "Deep Learning Core<br />Architecture",
        "ImageSegmentation": "Image Segmentation",
        "ObjectDetection": "Object Detection",
        "ImageClassification": "Image Classification",
        "3DDisplay": "3D Display",
        "ThematicMap": "Thematic Map",
        "QuantitativeInformation": "Quantitative Information"
    }
}
</i18n>

<style lang="less" scoped>
.m-sg-flow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 980px;
    height: 458px;
    margin: 40px auto;
    font-size: 14px;
    line-height: 20px;
    color: #B1B1B7;
    .lines-bg {
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .process {
        &-core {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            .cpu {
                img {
                    width: 173px;
                    height: 173px;
                    margin: -3px 0 10px 0;
                    &:hover {
                        animation: rotateIn; /* referring directly to the animation's @keyframe declaration */
                        animation-duration: 1s; /* don't forget to set a duration! */
                    }
                }
            }
        }
        &-units {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 158px;
            .item {
                position: relative;
                .img {
                    &:hover {
                        animation: rotateIn; /* referring directly to the animation's @keyframe declaration */
                        animation-duration: 1s; /* don't forget to set a duration! */
                    }
                }
                .text {
                    text-align: center;
                    .name {
                        position: absolute;
                        width: 100%;
                        width: 88px;
                        text-align: center;
                        &-top-left {
                            top: -40px;
                            left: -18px;
                        }
                        &-top {
                            top: -30px;
                            left: 50%;
                            transform: translate(-50%);
                        }
                        &-top-right {
                            top: -40px;
                            right: -18px;

                        }
                        &-bottom-left {
                            bottom: -40px;
                            left: -18px;
                        }
                        &-bottom {
                            bottom: -30px;
                            left: 50%;
                            transform: translate(-50%);
                        }
                        &-bottom-right {
                            bottom: -40px;
                            right: -18px;
                        }
                    }
                }
            }
            // flow-地理信息项
            .geo-info {
                .img {
                    width: 130px;
                    height: 130px;
                }
            }
        }
    }
}
</style>