<template>
    <div>
        <BaseIntro>
            <template v-slot:type>
                {{ $t('application') }}
            </template>
        </BaseIntro>
        <ul
            class="m-ai-video flex flex-col md:flex-row justify-center items-center gap-10 mt-5 transform md:scale-75 lg:scale-100	">
            <li class="m-ai-video-item" v-for="(video) in videoList" :key="video.key" @click="playVideo(video.key)">
                <figure>
                    <div class="video-img">
                        <img :src="video.poster" :alt="video.name">
                        <div class="play-btn">
                            <svg class="icon " aria-hidden="true">
                                <use xlink:href="#gshb_start"></use>
                            </svg>
                        </div>

                    </div>
                    <figcaption>{{ video.name }}</figcaption>
                </figure>
            </li>
        </ul>
        <vue-final-modal v-model="showVideoModal" name="envLandProtect" classes="modal-container"
            overlay-class="modal-overlay" content-class="modal-content" :esc-to-close="true" @opened="videoModalOpened">
            <p class="modal__title">{{ activeVideo.name }}</p>
            <div class="flex justify-center w-11/12 items-center m-auto">
                <video class="object-cover" v-if="showVideoModal" controls controlslist="nodownload"
                    ref="videoPalyPanel">
                    <source v-for="(sour) in activeVideo.source" :key="sour.key" :src="sour.src" :type="sour.type">
                    {{ $t('browserNotSupportVideo') }}
                </video>
            </div>
            <p class="modal__close" @click="playVideoClose">X</p>
        </vue-final-modal>
    </div>
</template>

<script>
import BaseIntro from '@/components/BaseIntro.vue'
import { VueFinalModal } from 'vue-final-modal'

export default {
    name: "AiControlVideo",
    components: {
        BaseIntro,
        VueFinalModal
    },
    computed: {
        activeVideo: function() {
            let video = this.videoList.find((video) => video.key === this.activeVideokey)
            return video
        },
        videoList: function() {
            return [
                {
                    ref: 'aiVideo',
                    key: 'video-1',
                    name: this.$t('complexEnv'),
                    poster: require("../assets/aiControl/videoImg/complex-env.png"),
                    source: [{
                        key: 'source-1',
                        src: require("../assets/aiControl/video/complex-env.mp4"),
                        type: 'video/mp4'
                    }]
                },
                {
                    ref: 'aiVideo',
                    key: 'video-2',
                    name: this.$t('overheadCar'),
                    poster: require("../assets/aiControl/videoImg/overhead-car.png"),
                    source: [{
                        key: 'source-2',
                        src: require("../assets/aiControl/video/overhead-car.mp4"),
                        type: 'video/mp4'
                    }]
                },
                {
                    ref: 'aiVideo',
                    key: 'video-3',
                    name: this.$t('envLandProtect'),
                    poster: require("../assets/aiControl/videoImg/env-land-protect.png"),
                    source: [{
                        key: 'source-3',
                        src: require("../assets/aiControl/video/env-land-protect.mp4"),
                        type: 'video/mp4'
                    }]
                }
            ]
        },
    },
    methods: {
        playVideo: function(videoKey) {
            this.activeVideokey = videoKey
            this.showVideoModal = true
            
        },
        playVideoClose: function() {
            this.showVideoModal = false
            this.$refs.videoPalyPanel.pause()
            
        },
        videoModalOpened: function() {
            this.$refs.videoPalyPanel.play()
        }
    },
    data: function() {
        return ({
            activeVideokey: 'video-1',
            showVideoModal: false,
        })
    }
}
</script>

<i18n>
{
    "zh": {
        "complexEnv": "复杂环境下人车检测与跟踪",
        "overheadCar": "空中俯视视角车辆检测与跟踪",
        "envLandProtect": "环保耕保等执法过程中违法工程机械识别"
    },
    "en":  {
        "complexEnv": "Detecting and tracking pedestrians and vehicles in a complex environment",
        "overheadCar": "Detecting and tracking vehicles from aerial overhead view",
        "envLandProtect": "Identification of illegal construction machinery in the process of law enforcement such as environmental protection and farming protection"
    }
}
</i18n>

<style lang="less" scoped>
.m-ai-video {
  &-item {
    width: 280px;
    height: 240px;
    margin: 0 15px;
    cursor: pointer;
    &:hover {
        color: #B1B1B7;
        .icon {
            animation: flash; /* referring directly to the animation's @keyframe declaration */
            animation-duration: 800ms; /* don't forget to set a duration! */
        }

    }
    .video-img{
        position: relative;
        img {
            width: 280px;
            height: 180px;
        }
        .play-btn {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 36px;
            height: 36px;
            background: rgba(7, 19, 50, 0.7);
            box-shadow: 0px 0px 3px 0px rgba(158, 180, 200, 0.3);
            border-radius: 18px;
            text-align: center;
            .icon {
                font-size: 14px;
                margin-top: 11px;
                transition: 1s ease;
            }
        }
    }
    
    figcaption {
      margin: 10px 0;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
  }
}

// modal
::v-deep .vfm__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .vfm--overlay {
    background: rgba(0, 0, 0, 0.8) !important;
}
::v-deep .vfm__content {
  position: relative;
}
.modal__close {
    box-sizing: border-box;
    z-index: 10;
    width: 40px;
    height: 40px;
    margin: 0 auto;
    border: 1px solid #B1B1B7;
    border-radius: 20px;
    line-height: 40px;
    color: #B1B1B7;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    &:hover {
        color: #fff;
        border-color: #fff;
        animation: pulse; /* referring directly to the animation's @keyframe declaration */
        animation-duration: 1s; /* don't forget to set a duration! */
    }
}

.modal__title {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
}
</style>