<template>
  <!-- 公司简介页start -->
  <section class="p-profile pt-32">
      <ProfileInfo />
      <ProfileValues />
      <ProfileCustomer />
  </section>
  <!-- 公司简介页end -->
</template>

<script>
// @ is an alias to /src
import ProfileInfo from '@/components/ProfileInfo.vue'
import ProfileValues from '@/components/ProfileValues.vue'
import ProfileCustomer from '@/components/ProfileCustomer.vue'


export default {
  name: 'Profile',
  components: {
      ProfileInfo,
      ProfileValues,
      ProfileCustomer
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.p-profile {
  background: url(../assets/profile/p-bg.png);
}
</style>