<template>
  <div class="m-indus-flow transform rotate-90 md:rotate-0 scale-100 md:scale-95 xl:scale-100">
    <img class="flow-bg" src="../assets/indusVision/m-indus-flow-bg.gif" alt="bg-black-hole">
    <section class="flow-required">
      <h4 class="type">{{ $t('firstPeriphery') }}</h4>
      <div class="part">
        <ul class="part-list animate__animated animate__backInLeft animate__slow">
          <li class="part-item part-item-1">
            <div class="flow-point">
              <div class="linear-box">{{ $t('industryCamera') }}</div>
            </div>
          </li>
          <li class="part-item part-item-2">
            <div class="flow-point">
              <div class="linear-box">{{ $t('positionSensor') }}</div>
            </div>
          </li>
        </ul>
        <img class="required-arrow transform origin-left scale-50 lg:scale-100 animate__animated animate__zoomIn"
          src="../assets/indusVision/flow/flow-required-arrow.png" alt="flow-required-arrow">
      </div>
    </section>
    <div class="flow-core">
      <h5 class="aux-equip">{{ $t('auxiliaryProcessingEquipment') }}</h5>
      <img class="core-arrow" src="../assets/indusVision/flow/flow-core-arrow.png" alt="flow-core-arrow">
      <h5 class="core-frame">{{ $t('deepLearningCoreArchitecture') }}</h5>
    </div>
    <section class="flow-selectable">
      <h4 class="type">{{ $t('secondPeriphery') }}</h4>
      <div class="part">
        <ul class="part-list  animate__animated animate__backInRight animate__slow">
          <li class="part-item ">
            <div class="flow-point">
              <div class="linear-box">{{ $t('externalTerminal') }}</div>
            </div>
          </li>
        </ul>
        <img class="selectable-arrow animate__animated animate__zoomIn"
          src="../assets/indusVision/flow/flow-selectable-arrow.png" alt="flow-selectable-arrow">
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'IndusVisionFlow',
}
</script>

<i18n>
{
    "zh": {
        "firstPeriphery": "第一外围（必选）",
        "industryCamera": "工业相机",
        "positionSensor": "位置传感器",
        "auxiliaryProcessingEquipment": "辅助处理设备",
        "deepLearningCoreArchitecture": "深度学习核心网络框架",
        "secondPeriphery": "第二外围（可选）",
        "externalTerminal": "外部终端/中继"

    },
    "en": {
        "firstPeriphery": "First periphery (required)",
        "industryCamera": "Industry Camera",
        "positionSensor": "Position Sensor",
        "auxiliaryProcessingEquipment": "Auxiliary Processing Equipment",
        "deepLearningCoreArchitecture": "Deep Learning Core Architecture",
        "secondPeriphery": "Second periphery (optional)",
        "externalTerminal": "External Terminal"
    }
}
</i18n>

<style lang="less" scoped>
@import "../lib/base.less";
@import "../lib/common.less";

.m-indus-flow {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 600px;
  .type {
    margin: -40px 0 40px 0;
    font-size: 24px;
    line-height: 34px;
    color: #B1B1B7;
  }
  .flow-point {
    .g-linear-btn();
    width: 170px;
    height: 45px;
    .linear-box {
      line-height: 45px;
      background: @gBgColor;
    }
  }
  .flow {
    &-bg {
      position: absolute;
      z-index: -1;
      width: 800px;
      opacity: .5;
    }
    &-required {
      width: 434px;
      height: 200px;
      .part {
        display: flex;
        &-item {
          &-1 {
            position: relative;
            top: -15px;
          }
          &-2 {
            position: relative;
            bottom: -5px;
          }
        }
        &-list {
          position: relative;

        }
      }
    }
    &-core {
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      .aux-equip {
        width: 144px;
        .lang-en & {
          width: 200px;
        }
      }
      .core-arrow {
        margin: 0 auto;
      }
      .core-frame {
        width: 138px;
        .lang-en & {
          width: 200px;
        }
      }
    }
    &-selectable {
      width: 434px;
      height: 200px;
      .type {
        text-align: right;
      }
      .part-item {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
</style>