<template>
    <BaseAlbumDrag 
        :album-data="albumData"
    />
</template>

<script>
import BaseAlbumDrag from '@/components/BaseAlbumDrag.vue'

export default {
    name: "IndusVisionAbnormalMatterDetection",
    components: {
        BaseAlbumDrag
    },
    computed: {
        albumData: function() {
            return {
                icon: require("../assets/indusVision/app/detect-production-env-sm.svg"),
                name: this.$t('indusVision.app.detectProductionEnv.name'),
                desc: this.$t('indusVision.app.detectProductionEnv.desc'),
                list: [{
                    key: "album_3",
                    name: "blend",
                    src: require("../assets/indusVision/album/detect-production-env-1.png")
                },{
                    key: "album_1",
                    name: "blend_coarse",
                    src: require("../assets/indusVision/album/detect-production-env-2.png")
                }]
            }
        }
    }
}
</script>

<i18n>
{

}
</i18n>

<style lang="less" scoped>

</style>