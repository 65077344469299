<template>
    <!-- 工业视觉页start -->
    <section class="relative w-full mb-16">
        <IndusVisionSys />
        <IndusVisionSoft />
        <IndusVisionApp />
        <!-- 相册抽屉start -->
        <router-view />
        <!-- 相册抽屉end -->
    </section>
    <!-- 工业视觉页end -->
</template>

<script>
// @ is an alias to /src
import IndusVisionSys from '@/components/IndusVisionSys.vue'
import IndusVisionSoft from '@/components/IndusVisionSoft.vue'
import IndusVisionApp from '@/components/IndusVisionApp.vue'

export default {
    name: 'IndusVision',
    components: {
        IndusVisionSys,
        IndusVisionSoft,
        IndusVisionApp
    }
}
</script>

<style lang="less" scoped>
    
</style>