<template>
    <BaseAlbum 
        class="m-album-carousel"
        :options="options"
        :showAside="false"
    >
        <template v-slot:article>
            <Swiper 
                class="pic"
                ref="albumSwiper" 
                :options="albumSwiperOptions"
                @slideChange="albumSwiperSlideChange"
            >
                <SwiperSlide 
                    v-for="(album) in albumList" 
                    :key="album.key"
                >
                    <figure class="pic-item" >
                        <img class="subject" :src="album.src" :alt="album.name">
                    </figure>
                </SwiperSlide>
            </Swiper>
        </template>
        <template v-slot:aside>

        </template>
        <template v-slot:footer>
             <footer class="m-album-ft">
                 <p class="thumb-swiper-btn thumb-swiper-prev">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#gshb_prev"></use>
                    </svg>
                 </p>
                <div 
                    class="album-thumb swiper-thumbs swiper-container" 
                    v-swiper:thumbSwiper="{}"
                    @slideChange="thumbSwiperSlideChange"
                >
                    <div class="swiper-wrapper">
                        <figure 
                            class="thumb-item swiper-slide"
                            v-for="(thumb) in albumThumb" 
                            :key="thumb.key"
                        >
                            <div class="subject">
                                <img class="linear-box" :src="thumb.src" :alt="thumb.name">
                            </div>
                            <figcaption class="name">{{thumb.name}}</figcaption>
                        </figure>
                    </div>
                </div>
                <p class="thumb-swiper-btn thumb-swiper-next">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#gshb_nex"></use>
                    </svg>
                </p>
                
            </footer>
        </template>
    </BaseAlbum>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import BaseAlbum from "@/components/BaseAlbum.vue"

export default {
    name: "BaseAlbumSwiper",
    components: {
        BaseAlbum,
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    props: {
        albumData: {
            type: Object,
            require: true,
            default: function() {
                return {
                    list:[]
                }
            }
        }
    },
    data: function() {
        return {
            albumSwiperOptions: {
                //autoplay:true,
                slidesPerView : 1,
                navigation: {
                    nextEl: '.thumb-swiper-next',
                    prevEl: '.thumb-swiper-prev',
                    disabledClass: "thumb-swiper-btn-disable"
                },
                thumbs: {
                    swiper: {
                        el: '.album-thumb',
                        spaceBetween: 10,
                        slidesPerView: 4,
                        slidesPerGroup : 4,
                        
                    },
                    slideThumbActiveClass : 'thumb-item-active',
                }
            }
        }
    },
    computed: {
        options: function() {
            const { icon, name, desc } = this.albumData;
            return {
                icon,
                name,
                desc
            }
        },
        albumList: function() {
            return this.albumData.list
        },
        albumThumb: function() {
            return this.albumData.thumb
        },
    },
    mounted: function() {
        
    },
    methods: {
        albumSwiperSlideChange: function() {
            // 组件有问题，手动切换缩略图分组
            const $albumSwiper = this.$refs.albumSwiper.$swiper
            const albumActiveIndex = $albumSwiper.activeIndex
            const theThumbGroup = albumActiveIndex / 4;

            // 缩略图分组后，activeIndex为分组序号
            const curThumbGroup = this.thumbSwiper.activeIndex

            if(theThumbGroup !== curThumbGroup) {
                this.thumbSwiper.slideTo(theThumbGroup)
            }
        },
        thumbSwiperSlideChange: function() {
        },
        
    },
}
</script>

<style lang="less">

</style>