<template>
  <section class="m-indus-app transform scale-95 xl:scale-100">
    <BaseIntro>
      <template v-slot:type>
        {{ $t('application') }}
      </template>
    </BaseIntro>
    <ul class="app-list flex flex-col lg:flex-row gap-10 justify-center items-center mt-8">
      <li class="app-item" @click="openAlbum('IndusVisionDiscernFrothInserts')">
        <img class="app-icon" src="../assets/indusVision/app/discern-froth-inserts.png"
          :alt="$t('indusVision.app.discernFrothInserts.name')">
        <h5 class="app-type">{{ $t('indusVision.app.discernFrothInserts.name') }}</h5>
        <p class="app-effect">{{ $t('indusVision.app.discernFrothInserts.effect') }}</p>
        <div class="app-function g-round-dot-btn">
          <span class="linear-box">{{ $t('indusVision.app.discernFrothInserts.desc') }}</span>
        </div>
      </li>
      <li class="app-item" @click="openAlbum('IndusVisiondiscernCarSeat')">
        <img class="app-icon" src="../assets/indusVision/app/discern-car-seat.png"
          :alt="$t('indusVision.app.discernCarSeat.name')">
        <h5 class="app-type">{{ $t('indusVision.app.discernCarSeat.name') }}</h5>
        <div class="app-effect">
          <div class="progress-describe">
            <span class="text">{{ $t('indusVision.app.discernCarSeat.effect') }}</span>
            <span class="percentage">99.6%</span>
          </div>
          <div class="g-progress">
            <div class="progress-bar" style="width: 99.6%"></div>
          </div>
        </div>
        <div class="app-function g-round-dot-btn">
          <span class="linear-box">{{ $t('indusVision.app.discernCarSeat.desc') }}</span>
        </div>
      </li>
      <li class="app-item" @click="openAlbum('IndusVisionDetectProductionEnv')">
        <img class="app-icon" src="../assets/indusVision/app/detect-production-env.png"
          :alt="$t('indusVision.app.detectProductionEnv.name')">
        <h5 class="app-type">{{ $t('indusVision.app.detectProductionEnv.name') }}</h5>
        <p class="app-effect"></p>
        <div class="app-function g-round-dot-btn">
          <span class="linear-box">{{ $t('indusVision.app.detectProductionEnv.desc') }}</span>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
// @ is an alias to /src
import BaseIntro from '@/components/BaseIntro.vue'

export default {
    name: 'IndusVisionApp',
    components: {
        BaseIntro,
    },
    methods: {
      openAlbum: function(routeName) {
        this.$router.push({ name:routeName })
      },
    }
}
</script>

<i18n>
{
    "zh": {
        
    },
    "en": {

    }
}
</i18n>

<style lang="less" scoped>
@import "../lib/common.less";

.m-indus-app {
  .m-intro {
    padding-top: 30px;
  }
  .app {
    &-item {
      @apply w-72 p-4 flex flex-col justify-center items-center ring-1 rounded-lg;
      &:hover,
      &-active{
        color: inherit;
        .app-icon {
          background: #0C101B;
          animation: swing; /* referring directly to the animation's @keyframe declaration */
          animation-duration: 1s; /* don't forget to set a duration! */
        }
        .progress-bar {
          animation: gshb-progress 2s ease-in;
        }
      }
    }
    &-icon {
      width: 160px;
      height: 160px;
    }
    &-type {
      margin: 20px 0;
      font-size: 18px;
      height: 40px;
      text-align: center;
    }
    &-effect {
      height: 28px;
      font-size: 14px;
      line-height: 25px;
      .text {
        width: 220px;
        text-align: left;
      }
      .progress-describe{
        display: flex;
        justify-content: space-between;
        .percentage {
          font-size: 20px;
          line-height: 28px;
          color: #98FFF2;
        }
      }
      .lang-en & {
        height: 53px;
      }
    }
    &-function {
      margin-top: 20px;
      .g-round-dot-btn();

      .linear-box{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        font-size: 14px;
        line-height: 20px;
      }
      .lang-en & {
        height: 60px;
      }
    }
  }
}
</style>