<template>
  <section class="m-sg-app">
    <BaseIntro>
      <template v-slot:type>
        {{ $t('application') }}
      </template>
    </BaseIntro>
    <ul class="flex flex-col md:flex-row justify-center items-center gap-10">
      <li class="app-item" @click="openAlbum('SenseGeoBuildingExtraction')">
        <div class="linear-box">
          <img class="app-icon" src="../assets/senseGeo/app/house-collect.png"
            :alt="$t('senseGeo.app.buildingExtraction.name')">
          <h5 class="app-type">{{ $t('senseGeo.app.buildingExtraction.name') }}</h5>
          <div class="app-sound-wave">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#gshb_start2"></use>
            </svg>
            <img class="sound-wave" src="../assets/senseGeo/app/sound-wave.png"
              :alt="$t('senseGeo.app.buildingExtraction.name')">
          </div>
        </div>
      </li>
      <li class="app-item" @click="openAlbum('SenseGeoLandCover')">
        <div class="linear-box">
          <img class="app-icon" src="../assets/senseGeo/app/surface-cover.png" :alt="$t('senseGeo.app.landCover.name')">
          <h5 class="app-type">{{ $t('senseGeo.app.landCover.name') }}</h5>
          <div class="app-sound-wave">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#gshb_start2"></use>
            </svg>
            <img class="sound-wave" src="../assets/senseGeo/app/sound-wave.png"
              :alt="$t('senseGeo.app.landCover.name')">
          </div>
        </div>

      </li>
      <li class="app-item" @click="openAlbum('SenseGeoRsObjectDetection')">
        <div class="linear-box">
          <img class="app-icon" src="../assets/senseGeo/app/sense-target-detect.png"
            :alt="$t('senseGeo.app.rsObjectDetection.name')">
          <h5 class="app-type">{{ $t('senseGeo.app.rsObjectDetection.name') }}</h5>
          <div class="app-sound-wave">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#gshb_start2"></use>
            </svg>
            <img class="sound-wave" src="../assets/senseGeo/app/sound-wave.png"
              :alt="$t('senseGeo.app.rsObjectDetection.name')">
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
// @ is an alias to /src
import BaseIntro from '@/components/BaseIntro.vue'

export default {
    name: 'SenseGeoApp',
    components: {
        BaseIntro,
    },
    methods: {
      openAlbum: function(routeName) {
        this.$router.push({ name:routeName })
      },
    }
}
</script>

<i18n>
{
    "zh": {

    },
    "en": {
        
    }
}
</i18n>

<style lang="less" scoped>
@import "../lib/base.less";
.m-sg-app {
  .m-intro {
    margin: 40px 0 30px 0;
  }
  .app {
    &-item {
      .g-linear-gradient-border(@border-radius: 10px; @background: @gBgColor);
      width: 220px;
      height: 228px;
      text-align: center;
      color: #B1B1B7;
      &:hover,
      &-active{
        color: inherit;
        .app-icon {
          animation: zoomIn; /* referring directly to the animation's @keyframe declaration */
          animation-duration: 2s; /* don't forget to set a duration! */
        }
        .sound-wave {
          animation: 1s gshb-wave ease-in;
        }
      }
      .linear-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    &-icon {
      //width: 90px;
      height: 85px;
    }
    &-type {
      margin: 20px 0;
      font-size: 18px;
      line-height: 25px;
    }
    &-sound-wave {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16px;
      line-height: 16px;
      .sound-wave {
        height: 10px;
        margin-left: 8px;
      }
    }
  }
}
</style>