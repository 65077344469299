<template>
    <section class="m-soft-swiper">
        <section class="soft-control">
            <BaseIntro>
                <template v-slot:type>
                    {{ pictureData.title }}
                </template>
            </BaseIntro>
            <ul class="flex flex-col lg:flex-row justify-center items-center gap-5 m-5">
                <li v-for="(picture, index) in pictureListActive" :key="picture.key"
                    :class="[{'soft-item-active': picture.isActive}, 'soft-item']" @click="slideTo(index)">
                    <span class="linear-box">{{ picture.name }}</span>
                </li>
            </ul>
        </section>
        <div class="soft-picture">
            <p class="hidden lg:block">
                <svg class="icon swiper-prev" aria-hidden="true">
                    <use xlink:href="#gshb_videoPrev2"></use>
                </svg>
            </p>
            <Swiper class="soft-picture-list" ref="softPictureSwiper" :options="videoSwiperOptions"
                @slideChange="slideChange">
                <SwiperSlide class="soft-picture-item" v-for="(picture) in pictureList" :key="picture.key">
                    <img :src="picture.src" :alt="picture.name" />
                </SwiperSlide>
            </Swiper>
            <p class="hidden lg:block">
                <svg class="icon swiper-next" aria-hidden="true">
                    <use xlink:href="#gshb_videoNext2"></use>
                </svg>
            </p>

        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import BaseIntro from '@/components/BaseIntro.vue'


export default {
    name: 'BaseSoftSwiper',
    components: {
        Swiper,
        SwiperSlide,
        BaseIntro
    },
    props: {
        pictureData: {
            type: Object,
            require: true,
            default: function() {
                return {
                    list: []
                }
            }
        }
    },
    data: function() {
        return {
            activeIndex: 0,
            videoSwiperOptions: {
                autoplay:true,
                navigation: {
                    prevEl: '.swiper-prev',
                    nextEl: '.swiper-next',
                    disabledClass: "icon-disable"
                }
            },
        }
    },
    computed: {
        $softPictureSwiper: function() {
            return this.$refs.softPictureSwiper.$swiper
        },
        pictureList: function() {
            return this.pictureData.list
        },
        pictureListActive: function() {
            const activeIndex = this.activeIndex

            const pictureList = this.pictureList.map((picture, index) => {
                picture.isActive = (index === activeIndex)
                return picture
            })
            return pictureList;
        }
    },
    methods: {
        slideTo: function(index) {
            this.$softPictureSwiper.slideTo(index)
        },
        slideChange: function() {
             // 更新激活后的slide
            this.activeIndex = this.$softPictureSwiper.activeIndex;
        }
    }
}
</script>

<i18n>
{

}
</i18n>

<style lang="less" scoped>
@import "../lib/common.less";

.m-soft-swiper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //height: 747px;
  background: rgba(24, 29, 46, 0.5);
  .m-intro {
    padding-top: 30px;
  }
  .soft {
    &-item {
      .g-linear-btn-small-mono();
      width: 150px;
      height: 40px;
      margin: 5px 10px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      .linear-box{
          padding: 0;
          line-height: 40px;
      }
      &-active,
      &:hover {
        .g-linear-btn-small();
        width: 150px;
        height: 40px;
        .linear-box{
            padding: 0;
            line-height: 40px;
        }
      }
      .lang-en & {
        width: 300px;
        &:hover {
            width: 300px;
        }
      }
    }
    &-picture {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      height: auto;
      margin: 0 auto;
      .icon {
        cursor: pointer;
        color: @gColor;
        font-size: 36px;      
        user-select: none;
        &-disable {
            cursor: default;
            color: #B1B1B7;
        }
      }
      &-list {
        display: flex;
        margin: 0 30px;
      }
      &-item {
        img {
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}
</style>