<template>
  <section class="m-sg-soft">
    <BaseSoftSwiper :picture-data="pictureData" />
  </section>
</template>

<script>
import BaseSoftSwiper from "@/components/BaseSoftSwiper.vue";

export default {
  name: "SenseGeoSoft",
  components: {
    BaseSoftSwiper,
  },
  computed: {
    pictureData: function() {
      return {
        title: this.$t("title"),
        list: [
          {
            key: "picture-1",
            isActive: true,
            name: this.$t("labelingMassiveRemoteSensingData"),
            src: require("../assets/senseGeo/soft/1-Labeling-Massive-Remote-Sensing-Data.png"),
          },
          {
            key: "picture-2",
            name: this.$t("nonParameterizedTrainingAndInference"),
            src: require("../assets/senseGeo/soft/2-Non-parameterized-Training-and-Inference.png"),
          },
          {
            key: "picture-3",
            name: this.$t("queryTheResults"),
            src: require("../assets/senseGeo/soft/3-Query-the-Results.png"),
          },
        ],
      };
    },
  },
};
</script>

<i18n>
{
    "zh": {
        "title": "SXAI智慧平台",
        "labelingMassiveRemoteSensingData": "海量遥感数据标注",
        "nonParameterizedTrainingAndInference": "零参数推训一体",
        "queryTheResults": "结果交互式查询"
    },
    "en": {
        "title": "SXAI Platform",
        "labelingMassiveRemoteSensingData": "Labeling Massive Remote Sensing Data",
        "nonParameterizedTrainingAndInference": "Non-parameterized Training and Inference",
        "queryTheResults": "Query the Results"
    }
}
</i18n>

<style lang="less" scoped>
.m-sg-soft {
  background: url(../assets/senseGeo/m-video-bg.jpg);
}
</style>
