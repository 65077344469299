<template>
    <!-- Ai控制页start -->
    <section class="p-ai-control">
        <AiControlCircle />
        <AiControlCore class="relative top-24" />
    </section>
    <!-- Ai控制页end -->
</template>

<script>
// @ is an alias to /src
import AiControlCircle from "@/components/AiControlCircle.vue"
import AiControlCore from "@/components/AiControlCore.vue"

export default {
    name: 'AiControl',
    components: {
        AiControlCircle,
        AiControlCore
    }
}
</script>

<style lang="less" scoped>
    .p-ai-control {
        position: relative;
        width: 100%;
        padding-bottom: 200px;
        .lang-en & {
            padding-bottom: 260px;
        }
        background: url(../assets/aiControl/p-ai-bg.png) repeat-x;
    }
</style>