<template>
  <BaseSoftSwiper :picture-data="pictureData" />
</template>

<script>
import BaseSoftSwiper from "@/components/BaseSoftSwiper.vue";

export default {
  name: "IndusVisionSoft",
  components: {
    BaseSoftSwiper,
  },
  computed: {
    pictureData: function() {
      return {
        title: this.$t("software"),
        list: [
          {
            key: "picture-1",
            isActive: true,
            name: this.$t("ghConfigurationPlatform"),
            src: require("../assets/indusVision/soft/1-GH-Configuration-Platform.png"),
          },
          {
            key: "picture-2",
            name: this.$t("ghIdentifierPlatform"),
            src: require("../assets/indusVision/soft/2-GH-Identifier-Platform.png"),
          },
        ],
      };
    },
  },
};
</script>

<i18n>
{
    "zh": {
        "ghConfigurationPlatform": "SX标定配置软件",
        "ghIdentifierPlatform": "SX识别软件"
    },
    "en": {
        "ghConfigurationPlatform": "SX Configuration Platform",
        "ghIdentifierPlatform": "SX Identifier Platform"
    }
}
</i18n>

<style lang="less" scoped></style>
